<template>
  <div class="zchjs">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/kucun' }">产品信息</el-breadcrumb-item>
        <el-breadcrumb-item >详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="descriptions flex">
        <div class="flex-1 xiangqing">
          <div class="flex">
            <div class="datitle flex-1">
              <el-tag style="float: left" size="mini" effect="dark">{{datas.typeName}}</el-tag>
              {{ datas.title }}
            </div>
            <shoucang  articleType="inventory-product" :articleId="$route.query.id"></shoucang>
          </div>
          <div  class="datitle1">发布时间：{{datas.createTime}}</div>
          <div class="compdesc">
            <div class="flex">
              <div class="images"><el-image fit="cover" :preview-src-list="[datas.images]" :src="datas.images"></el-image> </div>
              <el-descriptions class="margin-top flex-1" :colon="false"  :column="1" >
                <el-descriptions-item label="产品名称">{{datas.productName}}</el-descriptions-item>
                <el-descriptions-item label="所属行业">{{datas.industryName}}</el-descriptions-item>
                <el-descriptions-item label="产品品牌">{{datas.brandName}}</el-descriptions-item>
                <el-descriptions-item label="公司名称">{{datas.enterpriseName}}</el-descriptions-item>
                <el-descriptions-item >
                  <div class="jiage" v-if="datas.isFace">
                    <span class="num" style="font-size: 18px;margin-right: 5px">价格:</span><span>￥{{datas.price}}元/{{datas.unit}}</span>
                  </div>
                  <div class="jiage" v-else>面议</div>
                </el-descriptions-item>
                <el-descriptions-item label="联系人">{{datas.contacts}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{datas.phone}}</el-descriptions-item>
              </el-descriptions>
            </div>

           <div style="float: right"> <el-button type="primary" size="large" @click.stop.native="zixun()">我要询价</el-button></div>
        </div>
          <div class="compdesc" v-html="datas.detail">

          </div>
          <div class="mianze" style="margin-top: 150px;font-size: 12px;color: #4C4C4C;line-height: 25px">
            <h3>免责声明：</h3>
            <p>本网站作为信息服务平台，所有职位信息均由企业自行上传、发布。</p>
            <p> 用户发布信息的真实性、准确性等不作保证。</p>
            <p> 因上述原因导致的对其他用户或第三方造成的损失，本网站不承担责任。</p>
          </div>
        </div>
        <div class="tuijian">
            <h3>推荐</h3>
            <el-divider></el-divider>
             <div v-for="(li,index) in datas.recommendList" @click="goxq(li)">
               <div>{{li.title}}</div>
               <div style="color: #C4C4C4;font-size: 12px;padding-top: 5px">{{li.createTime}}</div>
               <el-divider></el-divider>
             </div>
        </div>
      </div>

    </div>
    <el-dialog title="发送" width="600px" append-to-body :visible.sync="dialogTableVisible">
      <el-form ref="form" :model="shen" label-width="120px" :rules="rules">
        <el-form-item label="咨询详情" prop="detail">
          <el-input rows="5"  style="width: 300px" type="textarea" v-model="shen.detail"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input   v-model="shen.contacts"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input type="number" v-model="shen.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-top: 20px" @click="submitForm()">保存</el-button>
          <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {enterprisedetail, parklist} from "../../request/moudle/park";
import fujian from "../common/fujian.vue";
import {inventorydetail, inventorysubmitconsult, tenderingdetail} from "../../request/moudle/demand";
import shoucang from "../../components/shoucang.vue";
export default {
  name: "zchj",
  components: {shoucang, fujian},
  data(){
    return {
      datas:'',
      shen:{},
      rules: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
        ]
      },
      dialogTableVisible:false
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    goxq(item){
      this.$router.push({
        path:'/kucunxq',
        query:{
          id:item.id,
        }
      })
      this.$router.go(0)
    },
    submitForm(){
      this.shen.inventoryId=this.$route.query.id
      this.$api.demand.inventorysubmitconsult(this.shen).then((res)=>{
        this.dialogTableVisible=false
        this.$message({
          type: "success",
          message: "提交成功!"
        });
      })
    },
    zixun(item){
      this.shen={}
      this.dialogTableVisible=true
    },
    goBack() {
      this.$router.go(-1)
    },
    loadxq(){
      this.$api.demand.inventorydetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 120px;
}
/deep/ .el-input{
  width: auto;
}
.jiage{
  background: #D83434;
  color: #fff;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  line-height: 34px;
  padding: 0 20px;
}
.zchjs{
  background: #F5F5F5;
}
.images{
  width: 300px;
  height:  250px;
  margin-right: 15px;
}
.xiangqing{
  background: #fff;
  padding: 20px;
}
.compdesc{
  margin-top: 50px;
}
.tuijian{
  width: 310px;
  margin-left: 60px;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.datitle{
  font-weight: bolder;
  text-align: center;
}
.datitle1{
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.header{
  height: 170px;
  background: url("../../assets/banner/gxjy.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 20px auto;
  /deep/ .el-col{
    padding: 15px ;
  }
  .descriptions{
    margin-top: 20px;
  }
}
</style>